import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// reactstrap components
import { NavLink, Nav, Container, Row, Col } from "reactstrap";

const DefaultFooter = () => {
  return (
    <footer className="site-footer section bg-dark text-contrast edge top-left">
      <Container className="py-3">
        <Row className="row gap-y text-center text-md-left">
          <Col md="4" className="mr-auto">
            <img
              src={require("assets/img/logo-light.png")}
              alt=""
              className="logo"
            />

            <p>
              BMaker Pro | A carefully crafted and powerful App that provides amazing signals
            </p>
          </Col>

          <Col md="2">
            <Nav className="flex-column" tag="nav">
              <NavLink className="py-2 text-contrast" href="#about">
                About
              </NavLink>
              <NavLink className="py-2 text-contrast" href="#about">
                Services
              </NavLink>
              <NavLink className="py-2 text-contrast" href="#features">
                Features
              </NavLink>
            </Nav>
          </Col>

          <Col md="2">
            <Nav className="flex-column" tag="nav">
              
              <NavLink href="/privacy" className="py-2 text-contrast" >
                Policy of Privacy
              </NavLink>
              <NavLink href="/eula" className="py-2 text-contrast" >
                Terms & Conditions
              </NavLink>
            </Nav>
          </Col>

          <Col md="2">
            <Nav className="flex-column" tag="nav">
              
              <NavLink className="nav-item py-2 text-contrast" href="https://play.google.com/store/apps/details?id=com.bmaker.app">
                Download Android
              </NavLink>
              
            </Nav>
          </Col>

          <Col md="2">
            <h6 className="py-2 small">Follow us</h6>

            <nav className="nav justify-content-around">
              <a
                href="https://facebook.com/bmakerpro"
                target="_blank"
                rel="noopener noreferrer"
                className="btn btn-circle btn-sm brand-facebook"
              >
                <FontAwesomeIcon icon={["fab", "facebook"]} />
              </a>
              <a href="https://twitter.com/bmakerpro" className="btn btn-circle btn-sm brand-twitter">
                <FontAwesomeIcon icon={["fab", "twitter"]} />
              </a>
              <a href="https://instagram.com/bmakerpro" className="btn btn-circle btn-sm brand-instagram">
                <FontAwesomeIcon icon={["fab", "instagram"]} />
              </a>
            </nav>
          </Col>
        </Row>

        <hr className="mt-5 op-5" />

        <Row className="small">
          <Col md="4">
            <p className="mt-2 mb-0 text-center text-md-left">
              © {new Date().getFullYear()}
              <a
                href="https://bmaker.pro"
                target="_blank"
                rel="noopener noreferrer"
              >
                 BMaker
              </a>
              . All Rights Reserved
            </p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default DefaultFooter;
