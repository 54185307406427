import React from "react";
import { Container, Row, Col } from "reactstrap";

const renderFeatures = () => {
  let features = [];
  const elements = [
    {
      icon: "pe-7s-diamond",
      title: "Powerful Design",
      description:
        "Get the best and valuable signals on the market, you will never operate alone."
    },
    {
      icon: "pe-7s-tools",
      title: "Professional Tools",
      description:
        "BMaker Systems ensure time-stamp and tracking time signals, we prioritize time because is critical on any market."
    },
    {
      icon: "pe-7s-light",
      title: "Get Ideas & Strategies",
      description:
        "In addition to precised information, in BMaker we want to fomentate the financial culture, so we are constantly improving our services about financial education."
    }
  ];

  elements.forEach((element, i) => {
    features.push(
      <Col md="4" className="py-4 rounded shadow-hover">
        <i className={`pe ${element.icon} pe-3x mb-3 text-primary`} />
        <h5 className="bold">{element.title}</h5>
        <p>{element.description}</p>
      </Col>
    );
  });

  return features;
};

const Features = () => {
  return (
    <section id="features" className="section features">
      <Container style={{marginBottom:'5rem'}}>
          <h2 className="heading-line text-center">Privacy Policy</h2>
        <div className="section-heading text-justify">
          <p className="lead text-muted">
          BMaker Política de privacidad
En BMaker queremos que sepa que respetamos su privacidad, la privacidad de nuestros Consultores y la privacidad de nuestros clientes. Este documento proporciona información sobre nuestra política en lo que respecta a la recopilación, el uso y la divulgación de información personal. También describe los derechos de nuestros clientes a limitar el uso de recopilación o divulgación de información personal. La información personal puede incluir su nombre, correo electrónico, dirección postal y / o comercial, números de teléfono u otra información que lo identifique personalmente. Nosotros en BMaker Mantenemos esta información personal en serio y mantenemos esta información en la más estricta confidencialidad. No vendemos ninguna información a terceros. También mantenemos un sitio web seguro en todas las páginas que requieren que proporciones información personal. Esta política se aplica a los usuarios de BMakerde servicios en cualquier parte del mundo.

Las prácticas descritas en esta política están sujetas a las leyes aplicables en los lugares en los que operamos. Esto significa que solo participamos en las prácticas descritas en esta política en un país o región en particular si lo permiten las leyes de esos lugares. Comuníquese con nosotros si tiene preguntas sobre nuestras prácticas en su país o región.

Controlador de datos:

BMaker proporciona servicios a usuarios de todo el mundo. Si utiliza nuestros servicios en cualquier parte del mundo, Amazon Web Services (AWS) es el controlador de datos de su información.

Procesamos información personal dentro y fuera de los Estados Unidos.


<h3 className="heading-line text-center m-5">¿Qué información recopilamos?
</h3>


BMaker recopila información en el Sitio en dos etapas diferentes. Primero, recopilamos información agregada anónima (como un nombre de dominio o una dirección IP) de todos los visitantes del Sitio. Este tipo de información nunca está vinculada a ninguna información de identificación personal y solo se usa en conjunto para informes estadísticos generales sobre el uso del Sitio.

En segundo lugar, requerimos el envío de cierta información de identificación personal cuando utiliza los servicios disponibles para el sitio. Recopilamos información sobre usted cuando se registra en nuestro sitio, realiza un pedido o completa un formulario. Al realizar un pedido o registrarse en nuestro sitio, según corresponda, se le puede solicitar que ingrese su: nombre, dirección de correo electrónico, dirección postal, número de teléfono, información de la tarjeta de crédito, información bancaria o número de seguro social. Sin embargo, puede visitar nuestro sitio de forma anónima.

Como la mayoría de los sitios web, utilizamos cookies y / o balizas web para mejorar su experiencia, recopilar información general sobre los visitantes y realizar un seguimiento de las visitas a nuestro sitio web. 
En BMaker, Inc. somos conscientes de nuestras responsabilidades como buenos ciudadanos de Internet y estamos dedicados a proteger los derechos de privacidad de otros ciudadanos de Internet. BMaker, Inc. se opone firmemente al spam. Cualquier Usuario que utilice el sitio web, la aplicación o los productos digitales para enviar spam será inmediatamente bloqueado y la (s) cuenta (s) relacionada (s) se deshabilitarán sin reembolso de los productos comprados previamente. Para cualquier queja sobre correos electrónicos no deseados, escríbanos a support@BMaker.com. Puede optar por no recibir correos electrónicos promocionales de BMaker aquí. También puede optar por no recibir correos electrónicos y otros mensajes de BMaker siguiendo las instrucciones de esos mensajes. Tenga en cuenta que si opta por no participar, es posible que aún le enviemos mensajes no promocionales.

<h3 className="heading-line text-center m-5">Términos de Uso
</h3>


Visite también nuestra sección de Términos de uso que establece el uso, las renuncias y las limitaciones de responsabilidad que rigen el uso de nuestro sitio web en Enlace

Tu consentimiento:

Al utilizar nuestro sitio, usted acepta nuestra política de privacidad del sitio web. Cambios en nuestra política de privacidad Si decidimos cambiar nuestra política de privacidad, publicaremos esos cambios en esta página.

Actualizaciones de políticas:

Ocasionalmente podemos actualizar esta política.
Ocasionalmente podemos actualizar esta política. Si realizamos cambios significativos, le notificaremos los cambios por correo electrónico. En la medida en que lo permita la ley aplicable, al utilizar nuestros servicios después de dicho aviso, usted acepta nuestras actualizaciones de esta política.
Le recomendamos que revise periódicamente esta política para obtener la información más reciente sobre nuestras prácticas de privacidad. También pondremos a disposición versiones anteriores de nuestras políticas de privacidad para su revisión.

<h3 className="heading-line text-center m-5">Contáctanos:
</h3>

Si tiene alguna pregunta sobre esta política de privacidad, puede comunicarse con nosotros utilizando la información a continuación.

BMaker

Support@BMaker.com
          </p>
        </div>

      </Container>
    </section>
  );
};

export default Features;
