import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

const MoreInfoButton = props => {
  return (
    <a href={"https://app.bmaker.pro"} target="_self" className={`more-link ${props.className}`}>
      {props.text}
      <FontAwesomeIcon
        icon={["fas", "long-arrow-alt-right"]}
        className="icon"
      />
    </a>
  );
};

export default MoreInfoButton;
