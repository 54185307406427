import React from "react";
import { Container, Row, Col } from "reactstrap";

const renderFeatures = () => {
  let features = [];
  const elements = [
    {
      icon: "pe-7s-diamond",
      title: "Powerful Design",
      description:
        "Get the best and valuable signals on the market, you will never operate alone."
    },
    {
      icon: "pe-7s-tools",
      title: "Professional Tools",
      description:
        "BMaker Systems ensure time-stamp and tracking time signals, we prioritize time because is critical on any market."
    },
    {
      icon: "pe-7s-light",
      title: "Get Ideas & Strategies",
      description:
        "In addition to precised information, in BMaker we want to fomentate the financial culture, so we are constantly improving our services about financial education."
    }
  ];

  elements.forEach((element, i) => {
    features.push(
      <Col md="4" className="py-4 rounded shadow-hover">
        <i className={`pe ${element.icon} pe-3x mb-3 text-primary`} />
        <h5 className="bold">{element.title}</h5>
        <p>{element.description}</p>
      </Col>
    );
  });

  return features;
};

const Features = () => {
  return (
    <section id="about" className="section features">
      <Container>
        <div className="section-heading text-center">
          <h2 className="heading-line">Premium features</h2>
          <p className="lead text-muted">
          Market trading signal systems are used by a high volume of traders all over the world to aid them in making critical decisions about their trades. With BMaker App you will be able to enter and exit the market according with experimented traders around the world, so you will not need to worry in carries the complex analysis.
          </p>
        </div>

        <Row className="gap-y text-center text-md-left">{renderFeatures()}</Row>
      </Container>
    </section>
  );
};

export default Features;
