import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

// routing components
import Index from "views/Index.jsx";
import Alternative1 from "views/landing/Alternative1.jsx";
import Alternative2 from "views/landing/Alternative2.jsx";
import Alternative3 from "views/landing/Alternative3.jsx";
import Terms from "views/landing/Terms.jsx";
import Privacy from "views/landing/Privacy.jsx";

// global template script file
import "./laapp.js";
import RequestDelete from "views/landing/RequestDelete.jsx";

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Route path="/" exact render={props => <Alternative2 {...props} />} />
      <Route path="/privacy" exact render={props => <Privacy {...props} />} />
      <Route path="/request-delete" exact render={props => <RequestDelete {...props} />} />
      <Route path="/eula" exact render={props => <Terms {...props} />} />



      {/* <Route
        path="/alter-1"
        exact
        render={props => <Alternative1 {...props} />}
      />
      <Route
        path="/alter-2"
        exact
        render={props => <Alternative2 {...props} />}
      />
      <Route
        path="/alter-3"
        exact
        render={props => <Alternative3 {...props} />}
      /> */}

      <Redirect to="/" />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
